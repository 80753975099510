import { observer } from "mobx-react"
import { useCallback } from "react"
import { t } from "@lingui/macro"
import { Favorite, FavoriteBorder, MoreHoriz } from "@mui/icons-material"
import { Stack } from "@mui/material"

import {
    Body,
    Author,
    TimeSince,
    Image,
    LikeButton,
    LikeFooter,
    OpenLikesModalButton,
    ActionDropdownPosition,
    InternalObjectIDContainer,
} from "./styled"

import {
    LinkTypography,
    CommentText,
} from "src/modals/view-community-post/styled"

import { formatDateTime } from "src/lib/date"
import { ConfirmModal } from "src/modals/confirm"
import { ActionDropdown } from "src/components/ActionDropdown"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"

import {
    IComment,
    ViewCommunityPostStore,
} from "src/modals/view-community-post/store"
import { CommunityLikesModal } from "src/modals/community-likes"
import { UrlLinksHandler } from "src/lib/urlLinksHandler"

interface IProps {
    comment: IComment
}

export const ViewCommentBody = observer((props: IProps) => {
    const store = useStore(ViewCommunityPostStore)
    const gstore = useStore(GlobalStore)

    const urlLinks = new UrlLinksHandler(props.comment.text)

    const handleEditCommentConfirmClick = useCallback(() => {
        gstore.modals.open(
            () => (
                <ConfirmModal
                    onConfirm={(confirm) => {
                        if (confirm) {
                            store.startEditingComment(props.comment.id)
                        }
                    }}
                    title={t`view-community-post-modal.edit-comment-swap-confirm-modal.title`}
                    content={t`view-community-post-modal.edit-comment-swap-confirm-modal.content`}
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [gstore.modals, store, props.comment.id])

    const handleEditCommentClick = useCallback(() => {
        if (store.isEditingOtherComment(props.comment.id)) {
            handleEditCommentConfirmClick()
        } else {
            store.startEditingComment(props.comment.id)
        }
    }, [store, props.comment, handleEditCommentConfirmClick])

    const handleDeleteCommentConfirm = useCallback(
        async (confirmed: boolean) => {
            if (confirmed) {
                await store.deleteComment(props.comment.id)
            }
        },
        [store, props],
    )

    const handleDeleteCommentConfirmModal = useCallback(() => {
        gstore.modals.open(
            () => (
                <ConfirmModal
                    onConfirm={handleDeleteCommentConfirm}
                    title={t`view-community-post-modal.delete-comment-confirm-modal.title`}
                    content={t`view-community-post-modal.delete-comment-confirm-modal.content`}
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [gstore.modals, handleDeleteCommentConfirm])

    const handleOpenLikesModalClick = useCallback(
        () =>
            gstore.modals.open(
                () => (
                    <CommunityLikesModal
                        postId={props.comment.postId}
                        commentId={props.comment.id}
                    />
                ),
                { variant: "slide-up-w600" },
            ),
        [gstore.modals, props.comment],
    )

    const isLiked = store.isCommentLikedByUser(
        props.comment.id,
        gstore.session.user?.adminId,
    )

    return (
        <Stack direction="column" spacing={0.5} flex={1}>
            <Body spacing="10px">
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex"
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Author>{props.comment.authorName}</Author>
                        <TimeSince
                            dateTime={props.comment.createdDate?.toLocaleString()}
                        >
                            {props.comment.createdDate != null
                                ? formatDateTime(props.comment.createdDate)
                                : null}
                        </TimeSince>
                        <InternalObjectIDContainer>
                            {t({
                                id: "view-community-post-modal.internal-object-id",
                                values: {
                                    internalObjectId:
                                        props.comment.internalObjectId,
                                },
                            })}
                        </InternalObjectIDContainer>
                    </Stack>
                    <div>
                        <ActionDropdownPosition>
                            {store.post.accessType === "WRITE" && (
                                <ActionDropdown
                                    icon={<MoreHoriz fontSize="small" />}
                                    items={[
                                        {
                                            text: t`view-community-post-modal.edit-comment-button`,
                                            onClick: handleEditCommentClick,
                                            hidden: !store.canEditComment(
                                                props.comment.id,
                                                gstore.session.user?.adminId,
                                            ),
                                        },
                                        {
                                            text: t`view-community-post-modal.delete-comment-button`,
                                            destructive: true,
                                            onClick:
                                                handleDeleteCommentConfirmModal,
                                        },
                                    ]}
                                />
                            )}
                        </ActionDropdownPosition>
                    </div>
                </Stack>
                <CommentText>{props.comment.text}</CommentText>
                {urlLinks.getUrlsFromText() !== undefined &&
                    urlLinks.getUrlsFromText()?.map((url, index) => (
                        <LinkTypography key={index} href={url} target="blank">
                            {url}
                        </LinkTypography>
                    ))}
                {props.comment.images.length > 0 && (
                    <Stack direction="row" spacing={1}>
                        {props.comment.images.map((image) => (
                            <a
                                key={image}
                                href={image}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Image src={image} />
                            </a>
                        ))}
                    </Stack>
                )}

                <LikeFooter direction="row" spacing={0.5} alignItems="center">
                    <OpenLikesModalButton
                        title={t({
                            id: `view-community-post-modal.open-likes-modal-title`,
                            values: { count: props.comment.likes.length },
                        })}
                        onClick={handleOpenLikesModalClick}
                        disabled={props.comment.likes.length === 0}
                        data-testid={`ViewCommentBody/OpenLikesModal/${props.comment.id}`}
                    >
                        {props.comment.likes.length > 0 ? (
                            <Favorite
                                fontSize="inherit"
                                color={isLiked ? "error" : "inherit"}
                            />
                        ) : (
                            <FavoriteBorder fontSize="inherit" />
                        )}
                        <span>{props.comment.likes.length}</span>
                    </OpenLikesModalButton>
                </LikeFooter>
            </Body>
            <div>
                <LikeButton
                    onClick={() =>
                        store.toggleCommentLike(
                            props.comment.id,
                            gstore.session.user?.adminId,
                        )
                    }
                    disabled={
                        gstore.loading.is(
                            ViewCommunityPostStore.LoadingKeys["like-comment"],
                        ) || store.post.accessType === "READ"
                    }
                >
                    {isLiked
                        ? t`view-community-post-modal.comment-unlike-button`
                        : t`view-community-post-modal.comment-like-button`}
                </LikeButton>
            </div>
        </Stack>
    )
})
